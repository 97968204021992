.App {
  width: 100%;
  height: 100vh;
  background-image: url('../public/images/Background for website 2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  z-index: -10;
  overflow: hidden; /* 禁止滚动条 */
  display: flex;
  min-height: 100vh; /* 页面最小高度为视窗高度 */
  overflow-y: auto; /* 允许滚动 */
}

/* 页头 */
.header {
  width: 100%;
  height: 101px; /* 默认高度，适用于较大屏幕 */
  background-image: url('../public/images/Header.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex; /* 将父容器设为 flex 布局 */
  justify-content: space-between; /* 左右两侧对齐 */
  align-items: center; /* 垂直居中 */
}

@media (max-width: 768px) {
  .header {
    height: 80px; /* 在较小屏幕上的高度 */
  }
}


@media (max-width: 468px) {
  .header {
    height: 60px; /* 在较小屏幕上的高度 */
  }
}
/* 左上角文字title */
.title {
  margin-top: 10px;
  margin-left: 30px;
  text-align: left;
  height: 55%; /* 占 Header 高度的 3/4 */
  width: auto; /* 等比例缩放 */
}


@media (max-width: 768px) {
  .title {
    margin-top: 8px;
    margin-left: 10px;
    height: auto; /* 依然占 Header 高度的 3/4 */
    width: 40%; /* 等比例缩放 */
  }
}

/* 右上角链接按钮 */
.social-buttons {
  display: flex;
  justify-content: flex-end; /* 靠右对齐 */
  align-items: center; /* 垂直居中 */
  gap: 4px; /* 按钮之间的间距 */
  flex-wrap: nowrap; /* 防止换行 */
  margin-right: 10px; /* 右侧边距 */
  max-width: 40%; /* 占 Header 的最大宽度 */
}

.social-buttons img {
  width: min(56px, 8vw); /* 按钮宽度自动缩放，最大不超过 56px */
  height: auto; /* 高度自动，保持比例 */
  max-height: 56px; /* 最大高度限制 */
  flex-shrink: 1; /* 自动缩小以适应父容器 */
  transition: transform 0.2s ease; /* 添加过渡效果 */
}

.social-buttons img:hover {
  transform: scale(1.1); /* 鼠标悬停时稍微放大 */
}


@media (max-width: 768px) {
  .social-buttons {
    gap: 2px; /* 按钮之间的间距 */
  }
}




/* 中上图标*/
.content-logo {
  margin: 0 auto; /* 自动左右边距，居中 */
  text-align: center; /* 确保文本居中 */
  max-width: 555px; /* 确保宽度明确 */
  height: auto; /* 保持高度比例 */
  margin-top: -10px;
  z-index: 10;
  transform: scale(1.0);
}

@media (max-width: 768px) {
  .content-logo {
    max-width: 355px; /* 确保宽度明确 */
    transform: scale(0.8);
  }
}

@media (max-width: 468px) {
  .content-logo {
    max-width: 255px; /* 确保宽度明确 */
    transform: scale(0.8);
  }
}
/* 中心提示语 */
.content-start-text {
  margin: 0 auto; /* 自动左右边距，居中 */
  text-align: center; /* 确保文本居中 */
  max-width: 1352px; /* 确保宽度明确 */
  height: auto; /* 保持高度比例 */
  margin-top: 30px;
  width: 85%;
  z-index: 10;
  transform: scale(1.0);
}

@media (max-width: 468px) {
  .content-start-text {
    transform: scale(0.8);
    margin-top: -5px;
  }
}

/* 表单 */
.input-content {
  flex: 1; /* 占据剩余空间 */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10%; /* 在底部留出 10% 的空白 */
  text-align: center; /* 横向居中 */
  padding-top: 3%;
}

.input-content img {
  margin-bottom: 20px; /* 图片底部间距 */
}

.input-content img.content-text {
  width: 60%; /* PC 上的宽度 */
  max-width: 638px; /* 最大宽度限制 */
  margin-top: 2%;
  z-index: 1;
}

@media (max-width: 768px) {
  .input-content img.content-text {
    margin-bottom: -2%;
    width: 65%; /* 手机上的宽度 */
    z-index: 1;
  }
}

@media (max-width: 468px) {
  .input-content img.content-text {
    margin-bottom: -5%;
    width: 45%; /* 手机上的宽度 */
    z-index: 1;
  }
}

.input-table {
  width: 40%; /* 宽度为 ContentText 的 80% */
  height: 179px;
  display: flex;
  flex-direction: column; /* 让子元素竖着排列 */
  align-items: center; /* 水平居中子元素 */
  gap: 20px; /* 子元素之间的间距 */
  z-index: 1;
}

.input-table .div1 {
  flex: 7;
  width: 800px;
  height: 96px;
  display: flex;
  justify-content: center; /* 居中对齐输入框 */
  align-items: center; /* 垂直居中对齐输入框 */
  max-width: 800px;
  background-image: url('../public/images/EnterEmail.png');
}

.input-table .div1 input[type="text"] {
  width: 90%;
  height: 55%;
  font-size: 280%;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  background-color: transparent; /* 背景透明 */
  border: none; /* 移除边框 */
  outline: none; /* 移除焦点外边框 */
  box-shadow: none; /* 移除浏览器的默认阴影 */
  -webkit-appearance: none; /* 兼容 Safari */
  box-shadow: none; /* 移除浏览器默认阴影 */
}

.input-table .div1 input[type="text"]::placeholder {
  opacity: 1; /* 确保 placeholder 的不透明度为 1 */
  font-size: 100%; /* 可选：设置字体大小 */
  font-family: 'Rubik', sans-serif; /* 设置字体 */
}

/* 针对手机端的媒体查询 */
@media (max-width: 768px) {
  .input-table {
    flex-direction: column;
    width: 70%; /* 手机上的宽度 */
    transform: scale(1);
  }

  .input-table .div1 {
    flex: 7;
    display: flex;
    justify-content: center; /* 居中对齐输入框 */
    background-image: url('../public/images/EnterEmail.png');
    transform: scale(0.5);
    margin-bottom: -7px;
  }

  .input-table .div1 input[type="text"] {
    font-size: 300%;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    background-color: transparent; /* 背景透明 */
    border: none; /* 移除边框 */
    outline: none; /* 移除焦点外边框 */
    box-shadow: none; /* 移除浏览器的默认阴影 */
    -webkit-appearance: none; /* 兼容 Safari */
  }

  .input-table .div1 input[type="text"]::placeholder {
    opacity: 1; /* 确保 placeholder 的不透明度为 1 */
    font-size: 100%; /* 可选：设置字体大小 */
    font-family: 'Rubik', sans-serif; /* 设置字体 */
  }

}


@media (max-width: 468px) {

  .input-table {
    flex-direction: column;
    width: 80%; /* 手机上的宽度 */
    transform: scale(1);
  }

  .input-table .div1 {
    flex: 7;
    display: flex;
    justify-content: center; /* 居中对齐输入框 */
    background-image: url('../public/images/EnterEmail.png');
    transform: scale(0.4);
    margin-bottom: -7px;
  }

  .input-table .div1 input[type="text"] {
    font-size: 300%;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    background-color: transparent; /* 背景透明 */
    border: none; /* 移除边框 */
    outline: none; /* 移除焦点外边框 */
    box-shadow: none; /* 移除浏览器的默认阴影 */
    -webkit-appearance: none; /* 兼容 Safari */
  }

  .input-table .div1 input[type="text"]::placeholder {
    opacity: 1; /* 确保 placeholder 的不透明度为 1 */
    font-size: 100%; /* 可选：设置字体大小 */
    font-family: 'Rubik', sans-serif; /* 设置字体 */
  }
}

.input-table .div2 {

  flex: 2.6;
  display: flex;
  justify-content: left; /* 居中对齐按钮 */
  align-items: center; /* 垂直居中对齐按钮 */
  justify-content: center; /* 居中对齐输入框 */
}

.input-table .div2 button {
  background-image: url('../public/images/SubmitButton.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 290px; /* 高度与 .div1 中的 input 一致 */
  max-height: 62px; /* 高度与 .div1 中的 input 一致 */
  min-width: 290px; /* 高度与 .div1 中的 input 一致 */
  min-height: 62px; /* 高度与 .div1 中的 input 一致 */
  width: 90%;
  height: 65%; /* 高度与 .div1 中的 input 一致 */
  border: 0;
  background-color: transparent;
}

.input-table .div2 button:hover {
  transform: scale(1.005); /* 鼠标悬停时稍微放大 */
}

.input-table .div2 button:active {
  transform: scale(0.95); /* 按压效果 */
}

/* 针对手机端的媒体查询 */
@media (max-width: 768px) {
  .input-table .div2 {
    width: 100%;
    flex: 5;
    justify-content: center; /* 居中对齐按钮 */
  }

  .input-table .div2 button {
    margin-top: -25%;
    transform: scale(0.55); /* 按压效果 */
    width: 90%;
    height: 65%; /* 高度与 .div1 中的 input 一致 */
  }
  .input-table .div2 button:hover {
    transform: scale(0.555); /* 鼠标悬停时稍微放大 */
  }

  .input-table .div2 button:active {
    transform: scale(0.5); /* 按压效果 */
  }
}

.message {
  font-family: 'Rubik', sans-serif;
  font-size: 140%;
  text-align: center;
  margin-top: 20px;
}


.img-char{
  max-width: 459px;
  max-height: 459px;
  width: 50%;
  height: auto;
  position: fixed; /* 固定定位 */
  bottom: 0; /* 距离底部 0 像素 */
  right: 0; /* 距离右侧 0 像素 */
  transform-origin: bottom right;
  z-index: 0;
}

.img-sub{
  max-width: 383px;
  height: auto;
  bottom: 40px; /* 距离底部 0 像素 */
  left: 40px; /* 距离右侧 0 像素 */
  transform-origin: bottom left;
  position: fixed; /* 固定定位 */
}

/* 小屏幕情况下上下分布 */
@media (max-width: 768px) {

  .img-sub {
    width: 40%;
    height: auto;
    bottom: 30px; /* 距离底部 0 像素 */
    left: 10px; /* 距离右侧 0 像素 */
    position: fixed; /* 固定定位 */
    transform: scale(1);
  }
}

/* 页脚内容 */
.foot-content {
  z-index: 1;
  display: flex; /* 使用 flex 布局 */
  flex-direction: column; /* 垂直布局 */
  justify-content: center; /* 内容水平居中 */
  align-items: center; /* 子元素垂直居中 */
  gap: 10px; /* 子元素之间的间距 */
  width: 100%; /* 占满父容器宽度 */
  padding: 20px 0; /* 上下内边距 */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* 添加顶部阴影 */
}

/* 子元素通用样式 */
.foot-content img,
.foot-content a {
  flex: none; /* 不允许子元素伸展 */
  text-align: center; /* 子元素内容居中 */
  bottom: 0; /* 重置底部偏移 */
}

/* 第一行：准备图片 */
.img-ready {
  padding-top: 20px; /* 调整与顶部的距离 */
}

/* 第二行：Discord 和 Steam */
.buttons-row {
  display: flex; /* 横向布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  gap: 20px; /* 按钮间距 */
}

.discord-button,
.steam-button {
  max-width: 300px; /* 限制按钮宽度 */
  cursor: pointer; /* 鼠标悬停样式 */
  transition: transform 0.2s ease-in-out; /* 悬停动画 */
}

.discord-button:hover,
.steam-button:hover {
  transform: scale(1.1); /* 鼠标悬停时放大 */
}


/* 小屏幕情况下上下分布 */
@media (max-width: 1920px) {
  .foot-content {
    transform: scale(0.7); /* 按压效果 */
  }

  .img-sub, .img-char {
    transform: scale(0.7); /* 按压效果 */
  }
}


/* 小屏幕情况下上下分布 */
@media (max-width: 1300px) {
  .foot-content {
    transform: scale(0.5); /* 按压效果 */
  }

  .img-sub, .img-char {
    transform: scale(0.5); /* 按压效果 */
  }
}

/* 小屏幕情况下上下分布 */
@media (max-width: 768px) {
  .foot-content {
    bottom: 1%; /* 距离页面底部为 0 */
    margin-top: -150px; /* 缩小与上方内容的距离 */
    flex-direction: column; /* 改为上下分布 */
    justify-content: center; /* 垂直居中 */
    gap: 20px; /* 每个子元素之间的间距 */
    transform: scale(0.35); /* 按压效果 */
  }

  .foot-content img, .foot-content a {
    flex: none; /* 取消均分 */
    width: auto; /* 根据内容调整宽度 */
  }


  .img-sub, .img-char {
    transform: scale(1); /* 按压效果 */
  }
}