@font-face {
  font-family: 'IntroCondBlackFree';
  src: url('../public/fonts/IntroCondBlackFree.otf');
}
@font-face {
  font-family: 'PingFang-SC-Bold';
  src: url('../public/fonts/PingFangBold.ttf');
}

/* 当屏幕宽度小于1200px时，调整布局 */
@media (max-width: 1200px) {
  .page {
    padding: 20px; /* 调整内边距 */
  }

  .image_1 {
    width: 50%; /* 根据屏幕大小调整图片大小 */
    height: auto; /* 保持图片比例 */
  }

  .text_1 {
    font-size: 1.5rem; /* 调整字体大小 */
  }
}

/* 当屏幕宽度小于768px时，进一步调整布局 */
@media (max-width: 768px) {
  .page {
    padding: 10px; /* 更小的内边距 */
  }

  .image_1 {
    width: 70%; /* 更小的图片大小 */
    height: auto;
  }

  .text_1 {
    font-size: 1.2rem; /* 更小的字体大小 */
  }
}


.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 1920px;
  height: auto; /* 自动调整高度 */
  overflow: hidden;
  margin: 0 auto; /* 水平居中 */
  display: flex; /* 使用 flex 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.group_1 {
  background-image: url(../public/images/503194bc.png);
  position: relative;
  width: 1920px;
  height: 700px;
}

.group_31 {
  width: 650px;
  height: 457px;
  margin: 70px 0 0 360px;
}

.image_1 {
  width: 204px;
  height: 97px;
}

.text_1 {
  width: 650px;
  height: 60px;
  overflow-wrap: break-word;
  color: rgba(238, 115, 6, 1);
  font-size: 60px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 60px;
  margin-top: 80px;
}

.text_2 {
  width: 491px;
  height: 48px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 48px;
  margin-top: 6px;
}

.text-group_13 {
  width: 513px;
  height: 96px;
  margin-top: 70px;
}

.text_3 {
  width: 280px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.paragraph_1 {
  width: 513px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 28px;
  margin-top: 12px;
}

.image_2 {
  width: 188px;
  height: 406px;
  margin: 160px 590px 0 132px;
}

.image_3 {
  position: absolute;
  left: 1306px;
  top: 132px;
  width: 249px;
  height: 531px;
}

.group_2 {
  height: 900px;
  background: url(../public/images/74c437c4.png)
  100% no-repeat;
  background-size: 100% 100%;
  width: 1920px;
  justify-content: flex-center;
}

.text-wrapper_8 {
  width: 471px;
  height: 62px;
  margin: 124px 0 0 360px;
}

.text_4 {
  width: 471px;
  height: 62px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 52px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 62px;
}

.section_8 {
  width: 1200px;
  height: 560px;
  margin: 30px 0 124px 360px;
}

.image-wrapper_1 {
  background-color: rgba(216, 105, 6, 1);
  height: 560px;
  width: 701px;
}

.image_4 {
  width: 338px;
  height: 152px;
  margin: 204px 0 0 182px;
}

.group_32 {
  width: 499px;
  height: 560px;
}

.image-wrapper_2 {
  background-color: rgba(73, 128, 218, 1);
  height: 280px;
  width: 499px;
}

.image_5 {
  width: 198px;
  height: 94px;
  margin: 93px 0 0 150px;
}

.image-wrapper_3 {
  background-color: rgba(32, 55, 92, 1);
  height: 280px;
  width: 499px;
}

.image_6 {
  width: 200px;
  height: 89px;
  margin: 96px 0 0 150px;
}

.group_3 {
  width: 1920px;
  height: 1340px;
  background: url(../public/images/dfa733cd.png)
  100% no-repeat;
  background-size: 100% 100%;
}

.text_5 {
  width: 589px;
  height: 62px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 52px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 62px;
  margin: 80px 0 0 360px;
}

.group_4 {
  width: 1322px;
  height: 1084px;
  background: url(../public/images/217f4a6f.png)
  0px 0px no-repeat;
  background-size: 1323px 1085px;
  margin: 30px 0 84px 299px;
}

.image-wrapper_4 {
  border-radius: 20px 20px 14px 14px;
  background-image: url(../public/images/e4051cb3.png);
  height: 640px;
  width: 1140px;
  margin: 30px 0 0 91px;
}

.image_7 {
  width: 120px;
  height: 120px;
  margin: 260px 0 0 510px;
}

.box_4 {
  width: 1140px;
  height: 320px;
  margin: 20px 0 74px 91px;
}

.group_6 {
  border-radius: 20px;
  background-image: url(../public/gifs/GIF-01.gif);
  background-position: center;
  background-size: cover;
  width: 560px;
  height: 320px;
}

.group_7 {
  border-radius: 20px;
  background-image: url(../public/gifs/GIF-02.gif);
  background-position: center;
  background-size: cover;
  width: 560px;
  height: 320px;
}

.group_8 {
  width: 1920px;
  height: 1182px;
  background: url(../public/images/9e78a5c7.png)
  100% no-repeat;
  background-size: 100% 100%;
  justify-content: flex-center;
}

.text_6 {
  width: 376px;
  height: 62px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 52px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 62px;
  margin: 80px 0 0 374px;
}

.group_33 {
  width: 1200px;
  height: 450px;
  margin: 30px 0 0 360px;
}

.image_8 {
  width: 800px;
  height: 450px;
}

.section_3 {
  background-color: rgba(18, 32, 56, 1);
  width: 400px;
  height: 450px;
}

.box_5 {
  position: relative;
  width: 221px;
  height: 43px;
  margin: 48px 0 0 90px;
}

.block_1 {
  box-shadow: inset 0px 0px 1px 2px rgba(48, 48, 48, 0.5);
  background-color: rgba(69, 69, 69, 1);
  width: 200px;
  height: 32px;
  border: 2.7px solid rgba(124, 124, 124, 1);
  margin: 5.300000000000182px 0 0 23.299999999999955px;
}

.thumbnail_1 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 23.700000000000045px;
}

.thumbnail_2 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_3 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_4 {
  width: 10px;
  height: 18px;
  margin-top: 7px;
}

.thumbnail_5 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_6 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_7 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_8 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_9 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.image-text_4 {
  width: 58px;
  height: 18px;
  margin: 7.699999999999818px 21.700000000000045px 0 0;
}

.thumbnail_10 {
  width: 10px;
  height: 18px;
}

.text-group_2 {
  width: 36px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(214, 214, 214, 1);
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
}

.label_1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 43px;
  height: 43px;
}

.box_6 {
  position: relative;
  width: 220px;
  height: 43px;
  margin: 9px 0 0 91px;
}

.section_4 {
  box-shadow: inset 0px 0px 1px 2px rgba(48, 48, 48, 0.5);
  background-color: rgba(69, 69, 69, 1);
  width: 199px;
  height: 32px;
  border: 2.7px solid rgba(124, 124, 124, 1);
  margin: 5.300000000000182px 0 0 23.299999999999955px;
}

.thumbnail_11 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 23.700000000000045px;
}

.thumbnail_12 {
  width: 10px;
  height: 18px;
  margin-top: 7px;
}

.thumbnail_13 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_14 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_15 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_16 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_17 {
  width: 10px;
  height: 18px;
  margin-top: 7px;
}

.thumbnail_18 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_19 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.image-text_5 {
  width: 56px;
  height: 18px;
  margin: 7.699999999999818px 22.700000000000045px 0 1px;
}

.thumbnail_20 {
  width: 10px;
  height: 18px;
}

.text-group_3 {
  width: 35px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(214, 214, 214, 1);
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
}

.label_2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 43px;
  height: 43px;
}

.box_7 {
  position: relative;
  width: 221px;
  height: 43px;
  margin: 10px 0 0 90px;
}

.group_12 {
  box-shadow: inset 0px 0px 1px 2px rgba(48, 48, 48, 0.5);
  background-color: rgba(69, 69, 69, 1);
  width: 200px;
  height: 32px;
  border: 2.7px solid rgba(124, 124, 124, 1);
  margin: 5.300000000000182px 0 0 23.299999999999955px;
}

.thumbnail_21 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 23.700000000000045px;
}

.thumbnail_22 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_23 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_24 {
  width: 10px;
  height: 18px;
  margin-top: 7px;
}

.thumbnail_25 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_26 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_27 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_28 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.thumbnail_29 {
  width: 10px;
  height: 18px;
  margin: 7.699999999999818px 0 0 1px;
}

.image-text_6 {
  width: 58px;
  height: 18px;
  margin: 7.699999999999818px 21.700000000000045px 0 0;
}

.thumbnail_30 {
  width: 10px;
  height: 18px;
}

.text-group_4 {
  width: 36px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(214, 214, 214, 1);
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
}

.label_3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 43px;
  height: 43px;
}

.box_8 {
  width: 273px;
  height: 56px;
  margin: 62px 0 0 64px;
}

.group_14 {
  height: 56px;
  background: url(../public/images/a36f02a0.png) -2px -1px
  no-repeat;
  background-size: 86px 64px;
  width: 82px;
  position: relative;
}

.text_7 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 34px;
  height: 18px;
  -webkit-text-stroke: 1.4px rgba(69, 69, 69, 1);
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin: 34px 0 0 24px;
}

.label_4 {
  position: absolute;
  left: 19px;
  top: -10px;
  width: 43px;
  height: 45px;
}

.group_15 {
  height: 56px;
  background: url(../public/images/8fcff5d8.png) -3px -1px
  no-repeat;
  background-size: 87px 64px;
  margin-left: 14px;
  width: 82px;
  position: relative;
}

.text_8 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 39px;
  height: 18px;
  -webkit-text-stroke: 1.4px rgba(69, 69, 69, 1);
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin: 34px 0 0 21px;
}

.label_5 {
  position: absolute;
  left: 19px;
  top: -11px;
  width: 44px;
  height: 45px;
}

.group_16 {
  height: 56px;
  background: url(../public/images/3dbb3f4a.png) -2px -1px
  no-repeat;
  background-size: 86px 64px;
  margin-left: 13px;
  width: 82px;
  position: relative;
}

.text_9 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 65px;
  height: 18px;
  -webkit-text-stroke: 1.4px rgba(69, 69, 69, 1);
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  letter-spacing: -0.699999988079071px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin: 34px 0 0 8px;
}

.label_6 {
  position: absolute;
  left: 19px;
  top: -10px;
  width: 43px;
  height: 43px;
}

.box_9 {
  width: 273px;
  height: 56px;
  margin: 33px 0 47px 64px;
}

.block_2 {
  height: 56px;
  background: url(../public/images/e7912412.png) -2px -2px
  no-repeat;
  background-size: 86px 64px;
  width: 82px;
  position: relative;
}

.text_10 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 35px;
  height: 18px;
  -webkit-text-stroke: 1.4px rgba(69, 69, 69, 1);
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin: 34px 0 0 23px;
}

.label_7 {
  position: absolute;
  left: 18px;
  top: -10px;
  width: 45px;
  height: 44px;
}

.block_3 {
  height: 56px;
  background: url(../public/images/9983c50e.png) -3px -2px
  no-repeat;
  background-size: 87px 64px;
  margin-left: 14px;
  width: 82px;
  position: relative;
}

.text_11 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 61px;
  height: 18px;
  -webkit-text-stroke: 1.4px rgba(69, 69, 69, 1);
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  letter-spacing: -0.699999988079071px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin: 34px 0 0 10px;
}

.image_9 {
  position: absolute;
  left: 17px;
  top: -19px;
  width: 51px;
  height: 52px;
}

.block_4 {
  height: 56px;
  background: url(../public/images/c4df0c25.png) -2px -2px
  no-repeat;
  background-size: 86px 64px;
  margin-left: 13px;
  width: 82px;
  position: relative;
}

.text_12 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 59px;
  height: 18px;
  -webkit-text-stroke: 1.4px rgba(69, 69, 69, 1);
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  letter-spacing: -0.699999988079071px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin: 34px 0 0 11px;
}

.label_8 {
  position: absolute;
  left: 20px;
  top: -10px;
  width: 42px;
  height: 45px;
}

.list_2 {
  width: 1200px;
  height: 450px;
  justify-content: space-between;
  margin: 30px 0 80px 360px;
}

.list-items_1-0 {
  background-image: url(../public/images/3577fb4f.png);
  width: 390px;
  height: 450px;
  margin-right: 15px;
  background: url(../public/images/83daea25.png);
}

.list-items_1-1 {
  background-image: url(../public/images/8c92d2e3.png);
  width: 390px;
  height: 450px;
  margin-right: 15px;
  background: url(../public/images/21995a80.png);
}

.list-items_1-2 {
  background-image: url(../public/images/e4b0b239.png);
  width: 390px;
  height: 450px;
  margin-right: 15px;
  background: url(../public/images/1dd08990.png);
}

.text-wrapper_9 {
  width: 1920px;
  height: 173px;
}

.text_13 {
  width: 369px;
  height: 62px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 52px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 62px;
  margin: 80px 0 0 760px;
}

.group_18 {
  width: 1920px;
  height: 1000px;
  background: url(../public/images/b55c2c65.png)
  100% no-repeat;
  background-size: 100% 100%;
  margin-top: -1px;
}

.text_14 {
  width: 593px;
  height: 62px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 52px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 62px;
  margin: 80px 0 0 360px;
}

.text-group_14 {
  width: 782px;
  height: 74px;
  margin: 40px 0 0 360px;
}

.text_15 {
  width: 469px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
}

.paragraph_2 {
  width: 782px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-top: 15px;
}

.image-wrapper_13 {
  border-radius: 20px;
  background-image: url(../public/images/b6668125.png);
  height: 674px;
  width: 1200px;
  margin: 30px 0 40px 360px;
}

.image_35 {
  width: 120px;
  height: 120px;
  margin: 277px 0 0 540px;
}

.group_22 {
  height: 1000px;
  background: url(../public/images/b918cc52.png)
  100% no-repeat;
  background-size: 100% 100%;
  width: 1920px;
  position: relative;
}

.text-wrapper_10 {
  width: 263px;
  height: 62px;
  margin: 84px 0 0 360px;
}

.text_19 {
  width: 263px;
  height: 62px;
  -webkit-text-stroke: 2px rgba(54, 108, 196, 1);
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 52px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 62px;
}

.section_9 {
  width: 1166px;
  height: 121px;
  margin: 57px 0 0 360px;
}

.text-group_15 {
  width: 418px;
  height: 121px;
}

.text_20 {
  width: 124px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
}

.paragraph_3 {
  width: 418px;
  height: 75px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 12px;
}

.text-group_16 {
  width: 418px;
  height: 121px;
}

.text_21 {
  width: 91px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
}

.paragraph_4 {
  width: 418px;
  height: 75px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 12px;
}

.image-wrapper_14 {
  width: 868px;
  height: 120px;
  margin: 40px 0 0 360px;
}

.image_17 {
  width: 120px;
  height: 120px;
}

.image_18 {
  width: 120px;
  height: 120px;
}

.section_10 {
  width: 1166px;
  height: 250px;
  margin: 10px 0 0 360px;
}

.image-wrapper_15 {
  width: 120px;
  height: 250px;
}

.image_19 {
  width: 120px;
  height: 120px;
}

.image_20 {
  width: 120px;
  height: 120px;
  margin-top: 10px;
}

.text-group_17 {
  width: 418px;
  height: 124px;
  margin-top: 86px;
}

.text_22 {
  width: 108px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
}

.paragraph_5 {
  width: 418px;
  height: 75px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 15px;
}

.section_11 {
  width: 1138px;
  height: 130px;
  margin: 0 0 126px 360px;
}

.image_21 {
  width: 120px;
  height: 120px;
  margin-top: 10px;
}

.image_22 {
  width: 342px;
  height: 116px;
  margin: 14px 0 0 160px;
}

.section_5 {
  background-image: url(../public/images/equipment_pic6.png);
  border-radius: 50%;
  width: 110px;
  height: 110px;
  border: 2px solid rgba(93, 136, 210, 1);
  margin-left: 126px;
}

.section_6 {
  background-image: url(../public/images/equipment_pic7.png);
  border-radius: 50%;
  width: 110px;
  height: 110px;
  border: 2px solid rgba(93, 136, 210, 1);
  margin-left: 30px;
}

.section_7 {
  background-image: url(../public/images/equipment_pic8.png);
  border-radius: 50%;
  width: 110px;
  height: 110px;
  border: 2px solid rgba(93, 136, 210, 1);
  margin-left: 30px;
}

.image_23 {
  position: absolute;
  left: 676px;
  top: 390px;
  width: 271px;
  height: 406px;
}

.group_26 {
  width: 1920px;
  height: 1000px;
  background: url(../public/images/4dc5e800.png)
  100% no-repeat;
  background-size: 100% 100%;
}

.text_23 {
  width: 127px;
  height: 62px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 52px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 62px;
  margin: 80px 0 0 360px;
}

.box_10 {
  width: 1197px;
  height: 535px;
  margin: 17px 0 0 360px;
}

.box_11 {
  width: 760px;
  height: 506px;
  margin-top: 29px;
}

.text-group_18 {
  width: 690px;
  height: 121px;
}

.text_24 {
  width: 127px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
}

.text_25 {
  width: 690px;
  height: 75px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 12px;
}

.image-wrapper_9 {
  height: 194px;
  background: url(../public/images/39c27623.png)
  0px 0px no-repeat;
  background-size: 704px 195px;
  margin-top: 19px;
  width: 704px;
}

.image_24 {
  width: 682px;
  height: 172px;
  margin: 12px 0 0 11px;
}

.text-group_19 {
  width: 760px;
  height: 96px;
  margin-top: 76px;
}

.text_26 {
  width: 127px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
}

.text_27 {
  width: 760px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 12px;
}

.image_25 {
  width: 417px;
  height: 512px;
}

.image-wrapper_10 {
  width: 1197px;
  height: 162px;
  background: url(../public/images/86213389.png)
  0px -1px no-repeat;
  background-size: 1197px 163px;
  margin: 20px 0 124px 360px;
}

.image_26 {
  width: 395px;
  height: 138px;
  margin: 11px 0 0 11px;
}

.image_27 {
  width: 387px;
  height: 138px;
  margin: 11px 0 0 10px;
}

.image_28 {
  width: 378px;
  height: 138px;
  margin: 11px 9px 0 7px;
}

.group_27 {
  position: relative;
  width: 1920px;
  height: 1000px;
  background: url(../public/images/541147c3.png)
  100% no-repeat;
  background-size: 100% 100%;
  margin-bottom: 176px;
}

.group_28 {
  position: relative;
  width: 1879px;
  height: 656px;
  background: url(../public/images/63c912fd.png)
  100% no-repeat;
  background-size: 100% 100%;
  margin: 42px 0 0 21px;
}

.text-wrapper_11 {
  width: 922px;
  height: 62px;
  margin: 85px 0 0 339px;
}

.text_28 {
  width: 244px;
  height: 62px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 52px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 62px;
}

.text_29 {
  width: 219px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
  margin-top: 26px;
}

.group_34 {
  width: 1241px;
  height: 206px;
  margin: 13px 0 0 339px;
}

.text-group_20 {
  width: 418px;
  height: 124px;
  margin-top: 28px;
}

.text_30 {
  width: 217px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
}

.paragraph_6 {
  width: 418px;
  height: 75px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 15px;
}

.box_12 {
  width: 538px;
  height: 206px;
}

.text_31 {
  width: 315px;
  height: 75px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
}

.image_29 {
  width: 327px;
  height: 112px;
  margin: 19px 0 0 211px;
}

.text-group_21 {
  width: 418px;
  height: 124px;
  margin: 44px 0 122px 728px;
}

.text_32 {
  width: 220px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
}

.paragraph_7 {
  width: 418px;
  height: 75px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 15px;
}

.image_30 {
  position: absolute;
  left: 1332px;
  top: 164px;
  width: 218px;
  height: 196px;
}

.box_13 {
  width: 806px;
  height: 124px;
  margin: 50px 0 128px 626px;
}

.image_31 {
  width: 247px;
  height: 106px;
  margin-top: 8px;
}

.text-wrapper_12 {
  width: 315px;
  height: 124px;
}

.text_33 {
  width: 220px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 28px;
  font-family: IntroCondBlackFree;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 34px;
}

.text_34 {
  width: 315px;
  height: 75px;
  overflow-wrap: break-word;
  color: rgba(72, 72, 72, 1);
  font-size: 18px;
  font-family: PingFang-SC-Bold;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 15px;
}

.image-wrapper_11 {
  height: 172px;
  background: url(../public/images/70694aff.png)
  100% no-repeat;
  background-size: 100% 100%;
  width: 342px;
  position: absolute;
  left: 284px;
  top: 669px;
}

.image_32 {
  width: 177px;
  height: 297px;
  margin: -235px 0 0 81px;
}

.image_33 {
  position: absolute;
  left: 740px;
  top: 617px;
  width: 212px;
  height: 270px;
}

.image-wrapper_12 {
  height: 245px;
  background: url(../public/images/a5c35a6c.png)
  100% no-repeat;
  background-size: 100% 100%;
  width: 191px;
  position: absolute;
  left: 1378px;
  top: 514px;
}

.image_34 {
  width: 175px;
  height: 73px;
  margin: 192px 0 0 43px;
}
